/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

    // Namespace contains common and page-specific functions.
    var TMBase = {

        // All pages
        'common': {
            init: function () {

                // Remove html.no-js
                $('html').removeClass('no-js');

                /**
                 * Open all external links in a new tab.
                 *
                 * This adds the ":external" selector to jQuery.
                 * Then queries all external links, tests them, and adds external attributes.
                 */
                $.expr[':'].external = function (obj) {
                    return !obj.href.match(/^mailto:/) &&
                        !obj.href.match(/^tel:/) &&
                        (obj.hostname !== location.hostname) &&
                        !obj.href.match(/^javascript:/) &&
                        !obj.href.match(/^$/);
                };
                $('a:external').attr('target', '_blank').attr('rel', 'external');

                /**
                 * Test the User Agent for Internet Explorer.
                 *
                 * If using Internet Explorer, add ie & ie-{version} classes
                 * to the root html element.
                 */
                (function internetExplorer() {
                    function detectIE() {
                        var ua = window.navigator.userAgent;

                        var msie = ua.indexOf('MSIE ');
                        if (msie > 0) {
                            // IE 10 or older => return version number
                            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
                        }

                        var trident = ua.indexOf('Trident/');
                        if (trident > 0) {
                            // IE 11 => return version number
                            var rv = ua.indexOf('rv:');
                            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
                        }

                        var edge = ua.indexOf('Edge/');
                        if (edge > 0) {
                            // Edge (IE 12+) => return version number
                            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
                        }

                        // other browser
                        return false;
                    }

                    var IEVersion = detectIE();
                    if (IEVersion) {
                        $('html').addClass('ie').addClass('ie-' + IEVersion);
                    }
                })();

                /**
                 * Primary navigation menu.
                 */
                (function primaryNavigation() {
                    var primaryNavigation = $('#js-primary-nav'),
                        menuToggle = $('#js-toggle-nav-main').unbind();

                    menuToggle.on('click', function (e) {
                        e.preventDefault();
                        $('body').toggleClass('menu-open');
                        primaryNavigation.slideToggle(function () {
                            if (primaryNavigation.is(':hidden')) {
                                primaryNavigation.removeAttr('style');
                            }
                        });
                    });
                })();

                /**
                 * Logo Carousel
                 */
                (function logoCarousel() {
                    var logoCarousel = $('.logo-carousel');
                    if (!logoCarousel.length) return false;

                    var logos = logoCarousel.find('.logo');
                    if (!logos.length) return false;

                    var owlEl = logoCarousel.find('.carousel');

                    // Add necessary classes
                    owlEl.addClass('owl-carousel owl-theme');

                    // Bind events to the carousel that will add classes to the last active item.
                    owlEl.on('initialized.owl.carousel changed.owl.carousel refreshed.owl.carousel to.owl.carousel dragged.owl.carousel', function (e) {
                        $(e.target).find('.owl-item.active').removeClass('last-on-screen');
                        $(e.target).find('.owl-item.active').last().addClass('last-on-screen');
                        $(e.target).find('.owl-item.active').last().prev().addClass('last-on-screen');
                    });

                    // Initialize the carousel.
                    owlEl.owlCarousel({
                        loop: true,
                        nav: false,
                        margin: 35,
                        stagePadding: 25,
                        dots: false,
                        responsive: {
                            0: {
                                items: 2,
                                slideBy: 2
                            },
                            568: {
                                items: 4,
                                slideBy: 4
                            },
                            768: {
                                items: 5,
                                slideBy: 5
                            },
                            1024: {
                                items: 8,
                                slideBy: 8
                            }
                        }
                    });

                    // Bind navigation events.
                    $('.logo-carousel-prev').on('click', function () {
                        owlEl.trigger('prev.owl.carousel');
                    });
                    $('.logo-carousel-next').on('click', function () {
                        owlEl.trigger('next.owl.carousel');
                    });

                })();
            },
            finalize: function () {
                // JavaScript to be fired on all pages, after page specific JS is fired
            }
        },

        // Pages with rotators
        'has_rotator': {
            init: function () {

                /**
                 * Initialize Rotators
                 */
                var rotators = $('.rotator-wrap.enabled');
                $(rotators).each(function (i) {
                    var owlEl = $(rotators[i]).find('.rotator'),
                        slides = $(rotators[i]).find('.banner'),
                        prevSlide = $(rotators[i]).find('.rotator-prev'),
                        nextSlide = $(rotators[i]).find('.rotator-next');

                    if (slides.length > 1) {
                        $(owlEl).owlCarousel({
                            animateOut: 'fadeOut',
                            autoplay: true,
                            autoplayTimeout: 8000,
                            autoplayHoverPause: false,
                            items: 1,
                            loop: true,
                            dots: false,
                            nav: false,
                            responsive: {
                                980: {
                                    dots: true
                                }
                            }
                        });
                        if (prevSlide.length) {
                            prevSlide.on('click', function () {
                                owlEl.trigger('prev.owl.carousel');
                            });
                        }
                        if (nextSlide.length) {
                            nextSlide.on('click', function () {
                                owlEl.trigger('next.owl.carousel');
                            });
                        }
                    }
                });
            }
        },

        // FAQ Page Template
        'page_template_template_faq_php': {
            init: function () {

                /**
                 * FAQ Functionality
                 */
                var faqs = $('.faqs');
                if (!faqs.length) return false;

                var showAnswerTriggers = faqs.find('.action.show-answer');

                // Show answer when clicked.
                showAnswerTriggers.on('click', function () {
                    var faq = $(this).closest('.faq');
                    var answer = faq.find('.answer');

                    faq.toggleClass('expanded');
                    answer.slideToggle();
                });
            }
        },

        // Product Archive
        'post_type_archive_newbright_product': {
            init: function () {
                console.log('test');
                var filterHeadings = $('.filter-heading');
                if (!filterHeadings.length) return false;

                filterHeadings.on('click', function () {
                    var filterGroup = $(this).closest('.product-filter');
                    filterGroup.toggleClass('expanded');
                });
            }
        },

        // Single Product Page
        'newbright_product_template_default': {
            init: function () {
                var thumbImage = $('.gallery-image-link');

                thumbImage.on('click', function () {
                    var imageSrc = $(this).find('img').attr('src');

                    $('.large-gallery-image-container').find('img').attr('src', imageSrc);
                });

                // jQuery Zoom for single product gallery images
                $('.large-gallery-image-container').zoom({magnify: 1.5, touch: true});

                $('a.videos-button').click( function(event){
                    event.preventDefault();

                    $('html, body').animate({
                        scrollTop: $( $.attr(this, 'href') ).offset().top
                    }, 500);
                });
            }
        },

        // Homepage
        'home' : {
            init: function () {
                // Close front page video window modal

                $('#close-video').click(function () {
                    $('.splash-screen').slideUp();
                });
            }
        }
    };

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = TMBase;
            funcname = (funcname === undefined) ? 'init' : funcname;
            fire = func !== '';
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === 'function';

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire('common');

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, 'finalize');
            });

            // Fire common finalize JS
            UTIL.fire('common', 'finalize');
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
